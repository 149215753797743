export const DEFAULT_SCALE_TOOLBAR_PROPS = {
  layout: 'horizontal',
  position: {
    right: 0,
    top: -140,
  },
  style: {
    width: 150,
    background: 'transparent',
    left: 'auto',
  },
};
